import { data } from "jquery";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import * as API from "../../utils/api";

const ACHPlaidLink = (props) => {
  const [publicKey, setPublicKey] = useState("");
  const {
    user,
    isCheck,
    amount,
    invoice_id,
    plaidStatus,
    plaidLevel,
    organization_key,
    auth,
    primary_color,
    disconnectBank,
    refreshData,
  } = props;

  useEffect(() => {
    getOrganizationData();
  }, [plaidLevel]);

  function linkExpressAccessToken() {
    API.expressLinkAccessToken(organization_key, invoice_id)
      .then((data) => {
        if (data.status_code === 200 && data.message === "success") {
          setPublicKey(data.data);
        }
        props.toggleLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching express token:", error);
        props.toggleLoading(false);
      });
  }

  function getExpressPlaidLinkToken() {
    API.expressPlaidLinkToken(invoice_id, organization_key).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        setPublicKey(data.data);
      }
    });
  }

  function linkConsumerAccessToken() {
    API.consumerLinkAccessToken(user)
      .then((data) => {
        if (data.status_code === 200 && data.message === "success") {
          setPublicKey(data.data);
        }
        props.toggleLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching express token:", error);
        props.toggleLoading(false);
      });
  }

  function getConsumerPlaidLinkToken() {
    API.getPlaidLinkToken(user, invoice_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        setPublicKey(data.data);
      }
    });
  }

  const getOrganizationData = async () => {
    let key;
    try {
      key = await API.getOrganizationData();
      //TODO:if props.user is null them we can go for express payment api call
    } catch (error) {}
    if ((!plaidStatus && auth) || (plaidStatus && auth && publicKey == "")) {
      if (plaidLevel === "Pending" || plaidLevel === "Failed") {
        linkConsumerAccessToken();
      } else {
        getConsumerPlaidLinkToken();
      }
    } else if (
      (!plaidStatus && !auth) ||
      (plaidStatus && !auth && publicKey == "")
    ) {
      if (plaidLevel === "Pending") {
        linkExpressAccessToken();
      } else {
        getExpressPlaidLinkToken();
      }
    }
  };

  const onSuccess = useCallback(
    (token, metadata) => {
      if (plaidLevel === "Failed") {
        refreshData();
      } else {
        props.sendDataBackToServer(token, metadata);
      }
      props.toggleLoading(true);
    },
    [amount]
  );
  const onExit = useCallback((error, metadata) => {
    if (error && error.error_code === "TOO_MANY_VERIFICATION_ATTEMPTS") {
      disconnectBank();
    }
  }, []);

  const config = {
    env:
      process.env.REACT_APP_STAGE === "production" ? "production" : "sandbox",
    token: publicKey,
    onSuccess,
    onExit,
  };

  const openPlaidModal = () => {
    setTimeout(() => {
      open();
    }, 1000);
    setTimeout(() => {
      props.toggleLoading(false);
    }, 3000);
  };

  const { open, ready, error } = usePlaidLink(config);
  return plaidStatus ? (
    <button
      className='cta mg-brand2-common-color'
      onClick={props.createPlaidPayment}
      disabled={!ready || !isCheck}
      style={{ backgroundColor: primary_color, borderColor: "#9B9B9B" }}
    >
      Submit Payment
    </button>
  ) : plaidLevel === "Pending" ? (
    <button
      className='cta mg-brand2-common-color'
      onClick={() => {
        openPlaidModal();
        props.toggleLoading(true);
      }}
      disabled={!ready || !isCheck}
      style={{ backgroundColor: primary_color, borderColor: "#9B9B9B" }}
    >
      Verify Plaid Account
    </button>
  ) : (
    <button
      className='cta mg-brand2-common-color'
      onClick={() => {
        openPlaidModal();
        props.toggleLoading(true);
      }}
      disabled={!ready || !isCheck}
      style={{ backgroundColor: primary_color, borderColor: "#9B9B9B" }}
    >
      Connect using Plaid
    </button>
  );
};
export default ACHPlaidLink;
