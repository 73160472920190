import React, { createContext, useEffect, useState } from "react";
import * as API from "../utils/api";
import { Cookies, withCookies } from "react-cookie";

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children, user }) => {
  const [progressPercent, setProgressPercent] = useState(0);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function checkUserPresence() {
    const cookies = new Cookies();

    let loggedUser = cookies.get("authUser") || {};
    if (isEmptyObject(loggedUser) && !loggedUser.client_admin) {
      setTimeout(checkUserPresence, 1500);
    } else {
      getOnboardData();
    }
  }

  useEffect(() => {
    checkUserPresence();
  }, []);

  const getOnboardData = async () => {
    try {
      const data = await API.getOrgDetails(user);
      if (data && data.status_code === 200) {
        setProgressPercent(
          parseFloat(data.data.organization.onboarding_percentage)
        );
      } else if (data && data.status_code === 401) {
        return;
      } else if (data && data.status_code === 500) {
        return;
      } else {
        setTimeout(getOnboardData, 2000);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   getOnboardData();
  // }, []);

  return (
    <OnboardingContext.Provider value={{ progressPercent, setProgressPercent }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
