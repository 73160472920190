import React from "react";
import styles from "./reports.module.scss";
import shortid from "shortid";
import { Table } from "reactstrap";
import moment from "moment";
import { getDollarAmount } from "../../../utils/helpers";

const InvoicesPaymentsReport = (props) => {
  const headings = [
    { invoiceKey: "txn_date", paymentKey: "date", label: "Date" },
    { invoiceKey: "invoice", paymentKey: "payment", label: "Transaction Type" },
    { invoiceKey: "descriptions", paymentKey: null, label: "Memo/Description" },
    { invoiceKey: "invoice_number", paymentKey: null, label: "Num" },
    { invoiceKey: "total_amt", paymentKey: "amount", label: "Amount" },
  ];

  const renderTableBody = (user, data) => {
    return (
      <>
        <tr>
          <td
            colSpan={headings.length}
            className={`${styles.rowData} ${styles.title}`}
            style={{ width: "fit-content" }}
          >
            {user}
          </td>
        </tr>
        {data &&
          data.map((item) => {
            const transactionRows = item.payment_transactions.map(
              (transaction) => (
                <tr
                  key={shortid.generate()}
                  className={`${styles.rowData} row-total`}
                >
                  {headings.map((heading) => {
                    let value = transaction[heading.paymentKey] || "";
                    if (heading.label === "Transaction Type") {
                      if (transaction.payment_type === "Credit Memo") {
                        value = "Credit Memo Payment";
                      } else {
                        value = "Payment";
                      }
                    } else if (heading.label === "Date") {
                      value = moment
                        .utc(transaction[heading.paymentKey])
                        .format("YYYY-MM-DD");
                    } else if (heading.label === "Amount") {
                      value = getDollarAmount(transaction[heading.paymentKey]);
                    }
                    return (
                      <td
                        key={shortid.generate()}
                        className={styles.rowData}
                        style={{
                          wordWrap:
                            heading.label === "Date" ? "break-word" : "",
                        }}
                      >
                        {value}
                      </td>
                    );
                  })}
                </tr>
              )
            );

            const invoiceRow = (
              <tr
                key={shortid.generate()}
                className={`${styles.rowData} row-total`}
              >
                {headings.map((heading) => {
                  let value = item[heading.invoiceKey] || "";
                  if (heading.label === "Transaction Type") {
                    value = "Invoice";
                  }
                  if (
                    heading.invoiceKey === "descriptions" &&
                    Array.isArray(value) &&
                    value.length > 0
                  ) {
                    value = (
                      <ul>
                        {value.map((item, index) => (
                          <li key={index}>&#9679; {item}</li>
                        ))}
                      </ul>
                    );
                  } else if (heading.label === "Amount") {
                    value = getDollarAmount(item[heading.invoiceKey]);
                  }
                  return (
                    <td
                      key={shortid.generate()}
                      className={`${styles.rowData}`}
                      style={{
                        wordWrap: heading.label === "Date" ? "break-word" : "",
                      }}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );

            return (
              <React.Fragment key={shortid.generate()}>
                {transactionRows}
                {invoiceRow}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <div>
      <Table striped hover className={styles.agingDetailReport}>
        <thead className={styles.headerFix}>
          <tr>
            {headings &&
              headings.map((header) => (
                <th
                  key={shortid.generate()}
                  className={styles.headingStyle}
                  style={{ borderTop: "none" }}
                >
                  {header.label}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((item) => {
              return renderTableBody(item.user_name, item.list);
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default InvoicesPaymentsReport;
