import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import {
  getDollarAmount,
  getInvoiceDownload,
  getInvoiceNumber,
} from "../../../utils/helpers";
import downloadImg from "../../././../assets/icons/download.png";
import * as API from "../../../utils/api";
import styles from "./reports.module.scss";

class ClientsCollectionsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.overAllTotal = [];
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };
  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(this.props.props.user, doc_id, "debts").then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            let flag = data.data.includes("base64");
            if (!flag) {
              data.data = "data:application/pdf;base64," + data.data;
            }
            let url = data.data;
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };
  getPastDue = (date) => {
    if (date) {
      let dateofvisit = moment(date);
      let today = moment(new Date(), "DD-MM-YYYY");
      return today.diff(dateofvisit, "days");
    }
    return "";
  };

  getReportData = (data) => {
    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Due Date",
        "Amount",
        "Open Balance",
        "Action",
      ],

      columns: data,
    };
  };

  renderOverAllTotal = () => {
    return (
      <tbody>
        <tr className='row-total over-all-total'>
          {this.overAllTotal &&
            this.overAllTotal.map((col, i) => {
              return (
                <td
                  key={shortid.generate()}
                  className={`${styles.rowData} total-sub-header`}
                >
                  {i === 0 ? col : getDollarAmount(Number(col))}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  getTotal = (title, e, total) => {
    total[0] = "Total for " + title;
    total[1] = total[1]
      ? Number(total[1]) + Number(e.total_amt)
      : Number(e.total_amt);
    total[2] = total[2]
      ? Number(total[2]) + Number(e.balance)
      : Number(e.balance);

    //calculate overall total
    this.overAllTotal[0] = "Total";
    this.overAllTotal[1] = this.overAllTotal[1]
      ? Number(this.overAllTotal[1]) + Number(e.total_amt)
      : Number(e.total_amt);
    this.overAllTotal[2] = this.overAllTotal[2]
      ? Number(this.overAllTotal[2]) + Number(e.balance)
      : Number(e.balance);

    return total;
  };
  handleClick = (data) => {
    let { history } = this.props.props;
    history.push({
      pathname: `/client/invoices/${data.id}`,
      state: {
        invoiceDetails: data,
        backPath: `/client/reports/3`,
      },
    });
  };

  getTableRows = (item) => {
    let downloadInvoice = this.downloadInvoice;
    let handleClick = this.handleClick;
    return [
      {
        title: this.getDate(item.txn_date),
      },
      {
        title: "Invoice",
      },
      {
        title: getInvoiceNumber(item, handleClick),
      },
      {
        title: this.getDate(item.due_date),
      },
      {
        title: getDollarAmount(
          Number(item.total_amt) + (parseFloat(item.tax_amount) || 0)
        ),
      },
      {
        title: getDollarAmount(Number(item.balance)),
      },
      {
        title: getInvoiceDownload(item, downloadInvoice),
      },
    ];
  };

  renderTableBody = (data) => {
    return (
      data &&
      data.map((item) => {
        let total = [];
        return (
          <tbody key={shortid.generate()}>
            {item.data && item.data.length ? (
              <tr>
                <td className={`${styles.rowData} inner-title`}>
                  {item.user_name ? item.user_name : ""}
                </td>
              </tr>
            ) : (
              <tr className='empty-row' />
            )}
            {item.data && item.data.length ? (
              item.data.map((d, i) => {
                let rows = this.getTableRows(d);
                total = this.getTotal(item.user_name, d, total);
                return (
                  <tr
                    key={shortid.generate()}
                    className={`${styles.rowData} row-total`}
                  >
                    {rows &&
                      rows.map((col) => {
                        return (
                          <td
                            key={shortid.generate()}
                            className={styles.rowData}
                          >
                            {col.title}
                          </td>
                        );
                      })}
                  </tr>
                );
              })
            ) : (
              <tr className='empty-row' />
            )}
            {total && total.length ? (
              <tr className='row-total'>
                {total.map((col, i) => {
                  return (
                    <td
                      key={shortid.generate()}
                      className={`${styles.rowData} total-sub-header`}
                    >
                      {i === 0 ? col : getDollarAmount(Number(col))}
                    </td>
                  );
                })}
              </tr>
            ) : (
              <tr className='empty-row' />
            )}
          </tbody>
        );
      })
    );
  };

  render() {
    let { data } = this.state;

    return (
      <Table
        hover
        striped
        className='aging-detail-report collection-report arrange-collection-report'
      >
        <thead className={styles.headerFix}>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={styles.headingStyle}
                    style={{ borderTop: "none" }}
                  >
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        {data && this.renderTableBody(data.columns)}
        {this.renderOverAllTotal()}
      </Table>
    );
  }
}

export default ClientsCollectionsReport;
