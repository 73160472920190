import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import shortid from "shortid";
import ArrowLink from "../../components/ArrowLink";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import { showToast } from "../../utils/helpers";
// Components
import Logo from "../Logo";

class AppAdminHeader extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let { user } = props;
    this.state = {
      id: "",
      dropdownIsActive: false,
      rowAction: [
        {
          title: this.getFullName(user),
          dataBehavior: "user-profile",
          icon: (
            <div
              className={`app-client-header-user-avatar app-client-header-icon`}
            >
              <span>{this.renderUserInitials(user)}</span>
            </div>
          ),
        },
        {
          title: "Sign Out",
          dataBehavior: "sign-out",
          icon: <div></div>,
        },
      ],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  getFullName = (user) => {
    let fullName = "";
    if (user.first_name) {
      fullName = user.first_name;
    }
    if (user.last_name) {
      fullName = fullName ? fullName + " " + user.last_name : user.last_name;
    }
    return fullName;
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label='Back'
        arrow='left'
        path={prevLink}
        optionalClassName='onboarding-header-prev-link'
      />
    );
  }

  // User Info
  renderUserInitials = (user) => {
    let initials = "";
    if (user.first_name && user.last_name) {
      initials = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return initials.toUpperCase();
  };

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "sign-out":
        history.push(`/sign-out`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <HeaderOverflowMenu showThreeDots={true}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses='drop-down-options'
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  render() {
    let { user, prevLink, logoPath, appName, hideNav } = this.props;
    let { error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <header className={`app-client-header`}>
        {prevLink && this.renderPrevLink(prevLink)}
        <Logo
          linkPath={logoPath}
          linkTitle={appName}
          adminPortal={true}
          hideNav={hideNav}
        />
        <div className={`app-client-header-content`}>
          <div className='overflow-menu'>{this.overflowMenu()}</div>
          <div className='app-menu'>
            <div className='app-client-header-user'>
              <a title='Profile' className={`app-client-header-user-avatar`}>
                <span>{this.renderUserInitials(user)}</span>
              </a>
            </div>
            <div
              className='app-client-header-menu'
              style={{ marginTop: "0px" }}
            >
              <Link
                className='app-client-header-user-nav-link cl-nav-link'
                to='/sign-out'
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(AppAdminHeader);
