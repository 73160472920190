import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import AppClientHeader from "../../../components/App/AppClientHeader";
import ClientsNav from "../../../components/Clients/ClientsNav";
import icCommGray from "../../../assets/images/quickbooks/ic_comm_gray.svg";
import greenTick from "../../../assets/images/greenTick.png";
import greyTick from "../../../assets/images/greyTIck.png";
import buildingTail from "../../../assets/images/quickbooks/building-teal.png";
import workflowTeal from "../../../assets/images/quickbooks/workflow-teal.png";
import paymentsGray from "../../../assets/images/quickbooks/payments-gray.png";
import * as API from "../../../utils/api";
import { errorMessage } from "../../../utils/helpers";
import Panel from "../../../components/App/Panel";
import QuickBookDataSyncView from "./QuickBookDataSyncView";
import ConnectToAccountView from "./ConnectToAccountView";
import QuickBookAddOrganizations from "./QuickBookAddOrganizations";
import Modal from "../../../components/App/Modals/Modal";
import InviteNewTeamMembers from "./InviteNewTeamMembers";
import PaymentPreferencesComponent from "./PaymentPreferencesComponent";
import { getBaseUrl } from "../../../utils/api";
import SetUpPaymentAccount from "./ssoPayementPortal/SetUpPaymentAccount";
import PaymentPortalPreview from "./PaymentPortalPreview";
import Cookies from "universal-cookie";
import Emitter from "../../../utils/event-emitter";
import styles from "../../../components/Buttons/button.module.scss";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import OnboardingContext from "../../../Context/OnboardingContext";

class WelcomeScreenView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgKey: "",
      OrgclientName: "",
      isLoading: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      companyData: {},
      progressPercent: 0,
      activeCollapse: "org",
      type: "",
      isQuickBookSyncOpen: false,
      isQBConnected: false,
      isEditPref: false,
      isSyncQb: false,
      syncDataToHeader: true,
      OrgclientData: "",
      stripePaymentsDetails: {},
      getOrganizationalDetailsFlag: true,
      appThemeData: {},
      imageResolution: "",
      overallPercentage: 0,
      isSyncToQuickBooksystem: true,
      isQBDataSyncedFromcheckAuthStatus: false,
      welcomeScreenData: [
        {
          label: "Set Up Your Account",
          icon: buildingTail,
          activeCollapse: "org",
          value: [
            {
              label: "Add Organization’s Detail",
              pageAction: "ADD_ORG",
              progressPercent: false,
            },
            {
              label: "Connect To Accounting System",
              pageAction: "CONNECT_ACCOUNT_SYSTEM",
              progressPercent: false,
            },
            {
              label: "Sync Data From QuickBooks",
              pageAction: "SYNC_ACCOUNT",
              progressPercent: false,
            },
            {
              label: "Invite Your Team Members",
              pageAction: "INVITE_TEAM_MEMBERS",
              progressPercent: false,
            },
          ],
        },
        {
          label: "Customize Your Workflow",
          icon: workflowTeal,
          activeCollapse: "customize_option",
          value: [
            {
              label: "Set Billing Details And Credit Terms",
              pageAction: "CREDIT_TERMS",
              progressPercent: false,
            },
            {
              label: "Set Your Invoicing Preferences",
              pageAction: "INVOICE_PREFERENCES",
              progressPercent: false,
            },
            {
              label: "Schedule Your Communications",
              pageAction: "SCHEDULE_COMMUNICATIONS",
              progressPercent: false,
            },
          ],
        },
        {
          label: "Get Setup For Payments",
          icon: paymentsGray,
          activeCollapse: "payment",
          value: [
            {
              label: "Set Up Payment Account",
              pageAction: "SetUp_Payment_Account",
              progressPercent: false,
            },
            {
              label: "Set Payment Preferences",
              pageAction: "PAYMENT_PREFERENCES",
              progressPercent: false,
            },
            {
              label: "Preview Payment Portal",
              pageAction: "PAYMENT_PORTAL_PREVIEW",
              progressPercent: false,
            },
          ],
        },
      ],
    };

    this.styles = {
      panel: {
        float: "left",
        width: "100%",
        margin: "auto",
        padding: "18px",
        backgroundColor: "#ffffff",
        border: "1px solid #ccc",
        borderRadius: "5px",
        marginBottom: "20px",
      },
      panelHeading: {
        paddingLeft: "20px",
        fontWeight: "bold",
        verticalAlign: "super",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Open Sans",
      },
      subMenus: {
        float: "left",
        width: "90%",
        padding: "12px 0px 12px 15px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Open Sans",
        borderTop: "1px solid #ccc",
      },
      activeCollapse: {
        height: "fit-content",
        transition: "height 0.5s",
        display: "block",
      },
      inActiveCollapse: {
        height: "0px",
        transition: "height 0.5s",
        display: "none",
      },
      apiCall: false,
    };
  }
  static contextType = OnboardingContext;

  componentWillMount() {
    this.initialDataCalls();
    this.getOrganizationalDetails();
  }

  checkAuthStatus = (cancel_sync = false) => {
    this.setState({ isLoading: true });
    API.checkAuthStatus(this.props.user, cancel_sync).then((data) => {
      this.setState((state) => ({
        ...state,
        isQBDataSyncedFromcheckAuthStatus: data.is_qbo_data_synced,
      }));
      if (data && data.status_code && data.status_code === 400) {
        this.setState({ isLoading: false });
        Emitter.emit("IS_QUICKBOOK_CONNECTED", false);
      } else if (
        (typeof data != "undefined" && data.data) ||
        (data != null && data.data)
      ) {
        if (data.data.status && data.data.status === "ACTIVE") {
          Emitter.emit("IS_QUICKBOOK_CONNECTED", true);
        }
        if (data.data.sync_status.sync_status == "COMPLETED") {
          Emitter.emit("IS_QUICKBOOK_SYNC", false);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  initialDataCalls = () => {
    this.setState({ isLoading: true });
    API.getOrganizationData().then((data) => {
      this.setState({
        orgKey: data.organization_key,
      });
      const { type } = this.props;
      this.getLogoColorsForPayment();

      if (type === "") {
        let url = new URL(window.location.href);
        let searchParams = new URLSearchParams(url.search);
        if (
          searchParams.get("email") != null ||
          searchParams.get("sub_id") != null ||
          searchParams.get("id_token") != null
        ) {
          localStorage.setItem("email", searchParams.get("email"));
          localStorage.setItem("sub_id", searchParams.get("sub_id"));
          localStorage.setItem("id_token", searchParams.get("id_token"));
          localStorage.setItem(
            "organization_key",
            searchParams.get("organization_key")
          );
          (async () => {
            const userCandidate = {
              email: searchParams.get("email"),
              organization_key:
                searchParams.get("organization_key") == null
                  ? data.organization_key
                  : searchParams.get("organization_key"),
              openid_id: searchParams.get("sub_id"),
              id_token: searchParams.get("id_token"),
              sub_id: searchParams.get("sub_id"),
            };
            let newData = JSON.parse(localStorage.getItem("newOrgData"));
            let userOrgData = newData ? newData : userCandidate;

            try {
              await API.setOauthSSOObj(userOrgData);
            } catch (error) {
              console.log("error catched welcome screen==>", error);
            }
            await API.ssoLogin(userOrgData).then((data) => {
              if ((typeof data != "undefined" || data != null) && !data.error) {
                if (!data.errors) {
                  const user = {
                    qb_connect:
                      data.is_qb_connected === null
                        ? false
                        : data.is_qb_connected,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    user_id: data.id,
                    user_auth_token: data.auth_token,
                    admin: data.admin,
                    is_employee: data.is_employee,
                    super_admin: data.super_admin,
                    onboarding_complete: data.accepted_on ? true : false,
                    is_onboarding_completed: data.accepted_on ? true : false,
                    client_admin: data.client_admin,
                    verified: data.verified,
                    verified_at: data.verified_at,
                    email: data.email,
                    user_hash: data.user_hash,
                    intercom_id: data.intercom_id,
                    organization_key: this.state.orgKey,
                  };
                  this.setState({ isLoading: false });
                  this.props.updateUserLogin(user);
                  this.getOrganizationalDetails();
                  this.checkAuthStatus();
                } else {
                  this.setState((state) => ({
                    ...state,
                    showFlash: true,
                    isLoading: false,
                    accessError: errorMessage,
                    errorMsg: data.errors,
                  }));
                }
              } else {
                this.setState((state) => ({
                  ...state,
                  isLoading: false,
                  error: {
                    hasAnyError: true,
                    statusCode: data
                      ? data.status ||
                        (data.message && data.message == "Access denied."
                          ? 401
                          : 500) ||
                        500
                      : 500,
                  },
                }));
              }
            });
            await API.getOrganizationData().then((data) => {
              this.setState({
                companyData: data,
                isLoading: false,
              });
            });
          })();
        }
      } else {
        this.checkAuthStatus();
        this.setState({ isLoading: false });
      }
    });
  };
  getLogoColorsForPayment() {
    API.getLogoColors()
      .then((response) => {
        const img = new Image();
        img.src = response.data.logo_url;
        img.onload = () => {
          if (img.height > 500 && img.width > 500) {
            this.setState({
              ...this.state,
              imageResolution: "moreThan500px",
              appThemeData: response.data,
            });
          } else {
            this.setState({
              ...this.state,
              imageResolution: "lessThan500px",
              appThemeData: response.data,
            });
          }
        };
      })
      .catch(() => {});
  }

  componentDidMount() {
    const { progressPercent } = this.context;

    if (localStorage.getItem("isSyncQb") === "true") {
      this.setState({ isSyncQb: true });
    }
    if (
      this.props.isEnabledpayment &&
      this.props.isEnabledpayment == "SetUp_Payment_Account"
    ) {
      this.handleAction("SetUp_Payment_Account");
    }
  }
  componentWillUnmount() {
    localStorage.removeItem("type");
  }

  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
      organization_key: this.state.orgKey,
    };
    let { welcomeScreenData } = this.state;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    if (user && user != null && authuser != null && authuser != undefined) {
      API.getOrgDetails(user).then((data) => {
        if (data && data.status_code == 200) {
          let onboardingPercentageFlag = data.data.onboarding_percentage;
          let organizationDetails = data.data.organization;
          welcomeScreenData[0].value[0].progressPercent =
            onboardingPercentageFlag.add_org_detail;
          welcomeScreenData[0].value[1].progressPercent =
            onboardingPercentageFlag.connect_to_accounting_system;
          welcomeScreenData[0].value[2].progressPercent =
            onboardingPercentageFlag.sync_qb_data;
          welcomeScreenData[0].value[3].progressPercent =
            onboardingPercentageFlag.invite_team;
          welcomeScreenData[1].value[0].progressPercent =
            onboardingPercentageFlag.org_billing_detail;
          welcomeScreenData[1].value[1].progressPercent =
            onboardingPercentageFlag.invoice_preference;
          welcomeScreenData[1].value[2].progressPercent =
            onboardingPercentageFlag.schedule_communications;
          welcomeScreenData[2].value[0].progressPercent =
            onboardingPercentageFlag.set_up_payment;
          welcomeScreenData[2].value[1].progressPercent =
            onboardingPercentageFlag.set_payment_prefs;
          welcomeScreenData[2].value[2].progressPercent =
            onboardingPercentageFlag.style_and_preview;

          let percentage = 0;

          Object.entries(onboardingPercentageFlag).forEach(([key, value]) => {
            if (key !== "style_and_preview") {
              if (
                key === "connect_to_accounting_system" &&
                organizationDetails.integrated_act_system === "standalone"
              ) {
                if (value) {
                  percentage = percentage + 11;
                }
              }
              if (
                key === "sync_qb_data" &&
                organizationDetails.integrated_act_system ===
                  "quickbooks_online"
              ) {
                if (value) {
                  percentage = percentage + 11;
                }
              }
              if (
                key !== "connect_to_accounting_system" &&
                key !== "sync_qb_data"
              ) {
                if (value) {
                  percentage = percentage + 11;
                }
              }
            } else {
              if (value) {
                percentage = percentage + 12;
              }
            }
          });
          this.setState({
            welcomeScreenData: welcomeScreenData,
            OrgclientName: data.data.organization.name,
            isSyncToQuickBooksystem:
              data.data.organization.integrated_act_system &&
              data.data.organization.integrated_act_system ===
                "quickbooks_online"
                ? true
                : false,
            OrgclientData: data.data,
            overallPercentage: percentage > 100 ? 100 : percentage,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    } else {
      // this.props.handleSignOut();
      this.setState({ isLoading: false });
    }
  };

  nextClick = () => {
    let returnFlag = false;
    if (this.state.overallPercentage < 100 && !returnFlag) {
      this.state.welcomeScreenData.map((data, index) => {
        if (returnFlag) {
          return "";
        } else {
          data.value.map((ele, idx) => {
            if (!ele.progressPercent && !returnFlag) {
              // this.setState({ type: ele.pageAction });
              // localStorage.setItem("type", ele.pageAction);
              this.handleAction(ele.pageAction);
              returnFlag = true;
              return "";
            }
          });
        }
      });
    } else {
      this.setState({ type: "ADD_ORG" });
      localStorage.setItem("type", "ADD_ORG");
      this.handleAction("ADD_ORG");
    }
  };
  handleAddOrganizaitions = () => {
    this.setState({ type: "SYNC_ACCOUNT" });
    localStorage.setItem("type", "SYNC_ACCOUNT");
  };

  handleAction = (type) => {
    let { history } = this.props;
    if (type == "SYNC_ACCOUNT") {
      this.setState({ type: "SYNC_ACCOUNT", syncDataToHeader: true });
      localStorage.setItem("type", type);
      history.push("/welcome-screen/account-preferences-sync-quickbook-data");
    } else if (type == "ADD_ORG") {
      localStorage.setItem("type", type);
      this.setState({ type: "ADD_ORG" });
      history.push("/welcome-screen/add-organization-details");
    } else if (type == "CONNECT_ACCOUNT_SYSTEM") {
      localStorage.setItem("type", type);
      this.setState({ type: "CONNECT_ACCOUNT_SYSTEM" });
      history.push("/welcome-screen/connect-account-system");
    } else if (type == "INVITE_TEAM_MEMBERS") {
      localStorage.setItem("type", type);
      this.setState({ type: "INVITE_TEAM_MEMBERS" });
      history.push("/welcome-screen/invite-your-team-members");
    } else if (type == "CREDIT_TERMS") {
      localStorage.setItem("type", type);
      this.setState({ type: "CREDIT_TERMS" });
      history.push("/welcome-screen/add-billing-details");
    } else if (type == "INVOICE_PREFERENCES") {
      localStorage.setItem("type", type);
      this.setState({ type: "INVOICE_PREFERENCES" });
      history.push("/welcome-screen/invoice-branding-and-comms");
    } else if (type == "SCHEDULE_COMMUNICATIONS") {
      localStorage.setItem("type", type);
      this.setState({ type: "SCHEDULE_COMMUNICATIONS" });
      history.push("/welcome-screen/schedule-communications");
    } else if (type == "SetUp_Payment_Account") {
      localStorage.setItem("type", "SetUp_Payment_Account");
      this.setState({ type: "SetUp_Payment_Account" });
      history.push("/welcome-screen/enable-payment");
    } else if (type == "PAYMENT_PREFERENCES") {
      localStorage.setItem("type", type);
      this.setState({ type: "PAYMENT_PREFERENCES" });
      history.push("/welcome-screen/payment-preferences");
    } else if (type == "PAYMENT_PORTAL_PREVIEW") {
      localStorage.setItem("type", type);
      this.setState({ type: "PAYMENT_PORTAL_PREVIEW" });
      history.push("/welcome-screen/payment-portal-preview");
    } else if (type == "GO_LIVE_DATE") {
      localStorage.setItem("type", type);
      this.setState({ type: "GO_LIVE_DATE" });
      history.push("/welcome-screen/schedule-go-live-date");
    } else if (type == "") {
      localStorage.setItem("type", type);
      this.setState({ type: "" });
      history.push("/welcome-screen");
    }
  };

  updateQBInsideBar = (data) => {
    if (data.isConnectedToQB) {
      this.setState({ isQBConnected: data.isConnectedToQB });
    } else if (data.lastSyncDate) {
      this.setState({ lastSyncDate: data.lastSyncDate });
    }
  };

  viewpreferences = (flag) => {
    this.setState({ isEditPref: flag });
  };

  accordionMenu = () => {
    return (
      <div className='sidebar-nav' style={this.styles.panel}>
        {this.state.welcomeScreenData.map((data, idx) => {
          return (
            <div style={{ float: "left", width: "100% " }}>
              <div
                style={{
                  borderTop: "2px solid #ccc",
                  padding: "10px 0px",
                  width: "100%",
                }}
              >
                <span className='sidebar-nav-menu-item-head-title'>
                  <img src={data.icon} width='30' height='30' />
                </span>
                <span
                  className='sidebar-nav-menu-item-head-title'
                  style={this.styles.panelHeading}
                >
                  {data.label}
                </span>
                <span
                  className='sidebar-nav-menu-item-head-help'
                  style={{ float: "right", marginTop: "7px" }}
                >
                  <button
                    type='button'
                    id={idx}
                    className={`btn-help client-caret ${
                      this.state.activeCollapse === data.activeCollapse
                        ? "expanded-welcome-screen"
                        : "expander-caret-welcome-screen"
                    } `}
                    onClick={() =>
                      this.handleExpandCollaps(data.activeCollapse)
                    }
                  />
                </span>
              </div>
              <div style={{ width: "100%" }}>{this.accordionSubMenu(data)}</div>
            </div>
          );
        })}
      </div>
    );
  };
  accordionSubMenu = (data) => {
    return (
      <>
        <div
          className={
            this.state.activeCollapse == data.activeCollapse
              ? "is-active-collapse"
              : "is-active-collapse close"
          }
        >
          <div style={{ paddingLeft: "50px" }}>
            {data.value.map((value, index) => {
              if (this.state.isSyncToQuickBooksystem) {
                if (!(value.pageAction === "CONNECT_ACCOUNT_SYSTEM")) {
                  return (
                    <>
                      <div style={this.styles.subMenus}>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            cursor: "pointer",
                          }}
                          id={index}
                          onClick={() => this.handleAction(value.pageAction)}
                        >
                          {value.label}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "10%",
                          float: "right",
                          borderTop: "1px solid #ccc",
                        }}
                      >
                        {value.progressPercent ? (
                          <img
                            style={{ float: "right" }}
                            onClick={() => this.handleAction(value.pageAction)}
                            src={greenTick}
                            width='38'
                            height='38'
                          />
                        ) : (
                          <img
                            style={{ float: "right" }}
                            onClick={() => this.handleAction(value.pageAction)}
                            src={greyTick}
                            width='38'
                            height='38'
                          />
                        )}
                      </div>
                    </>
                  );
                }
              } else {
                if (!(value.pageAction === "SYNC_ACCOUNT")) {
                  return (
                    <>
                      <div style={this.styles.subMenus}>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleAction(value.pageAction)}
                        >
                          {value.label}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "10%",
                          float: "right",
                          borderTop: "1px solid #ccc",
                        }}
                      >
                        {value.progressPercent ? (
                          <img
                            style={{ float: "right" }}
                            onClick={() => this.handleAction(value.pageAction)}
                            src={greenTick}
                            width='38'
                            height='38'
                          />
                        ) : (
                          <img
                            style={{ float: "right" }}
                            onClick={() => this.handleAction(value.pageAction)}
                            src={greyTick}
                            width='38'
                            height='38'
                          />
                        )}
                      </div>
                    </>
                  );
                }
              }
            })}
          </div>
        </div>
      </>
    );
  };

  back = () => {
    let { type } = this.state;
    this.setState({ type: "" });
    localStorage.setItem("type", "");
  };
  backAddOrg = () => {
    let { type } = this.state;
    this.setState({ type: "ADD_ORG" });
    localStorage.setItem("type", "ADD_ORG");
  };
  backTosyncData = () => {
    let { type } = this.state;
    this.setState({ type: "SYNC_ACCOUNT", syncDataToHeader: false });
    localStorage.setItem("type", "SYNC_ACCOUNT");
    this.handleAction("SYNC_ACCOUNT");
  };
  backToAccountConnection = () => {
    let { type } = this.state;
    this.setState({ type: "CONNECT_ACCOUNT_SYSTEM" });
    localStorage.setItem("type", "CONNECT_ACCOUNT_SYSTEM");
    this.handleAction("CONNECT_ACCOUNT_SYSTEM");
  };
  backToInviteTeam = () => {
    let { type } = this.state;
    this.setState({ type: "INVITE_TEAM_MEMBERS" });
    localStorage.setItem("INVITE_TEAM_MEMBERS");
  };
  handleExpandCollaps = (name) => {
    if (this.state.activeCollapse === name) {
      this.setState({ activeCollapse: "" });
    } else {
      this.setState({ activeCollapse: name });
    }
  };

  moreInfoClick = (e) => {
    e.stopPropagation();
  };

  syncQbIcon = (res) => {
    this.setState({ isSyncQb: res ? true : false });
  };
  syncDataToHeaders = (res) => {
    res(this.state.syncDataToHeader);
  };

  //get stripe connection details

  welcomeScreen = (clientName, percent) => {
    return this.state.isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <>
        <div className='sso-welcome-screen-cls'>
          <div className='wrap mg-welcome-screen'>
            <div>
              <div style={{ textAlign: "left" }}>
                <div>
                  <Helmet title={this.props.appName + " | Welcome"}></Helmet>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%" }}>
                      <p
                        style={{ fontWeight: 700, fontSize: "30px" }}
                        className='mg-head-bold'
                      >
                        Welcome To Setup
                      </p>
                    </div>
                    <div style={{ width: "25%" }} className={styles.btnBox}>
                      <PrimaryButton
                        isNextButton={true}
                        text={
                          this.state.overallPercentage == 0
                            ? "Start Setup"
                            : "Next"
                        }
                        title={``}
                        onClick={this.nextClick}
                      />
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div>
                      <p class='domain-label-cls'>
                        Our Setup Guide will walk you through the steps to
                        configure your new MakeGood account.
                      </p>
                    </div>
                    <div className='loader-div' style={{ padding: "15px 0px" }}>
                      <div className='sync-prt'>
                        <div
                          className='sync-fill-loader'
                          style={{ width: this.state.overallPercentage + "%" }}
                        />
                      </div>
                      <div className='per-text-div wt-small'>
                        <div
                          style={{
                            fontFamily: "'Open Sans',sans-serif",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {this.state.overallPercentage
                            ? Math.floor(this.state.overallPercentage) +
                              "% Complete"
                            : "0% Complete"}
                        </div>
                      </div>
                    </div>
                    <div>{this.accordionMenu()}</div>
                  </div>
                </div>
                {/* <div class='main-button-cls'>
                  <div style={{ float: "right" }}>
                    <a
                      className='cta mg-brand2-color'
                      style={{
                        padding: "0.5em 0.75em",
                        borderRadius: "5px",
                        // marginTop: "20px",
                        width: "200px",
                        marginBottom: "20px",
                        height: "50px",
                        borderRadius: "10px",
                        fontFamily: "Open Sans",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                      id='start_or_next'
                      onClick={this.nextClick}
                    >
                      {this.state.overallPercentage == 0
                        ? "Start Setup"
                        : "Next"}
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { progressPercent } = this.context;
    const { appName, type } = this.props;
    const { imageResolution, error } = this.state;
    if (
      this.props.user.admin === false &&
      this.props.user.client_admin === false &&
      (this.props.user.is_employee === false ||
        this.props.user.is_employee === null)
    ) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard/accounts/invoices",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (
      this.props.user.admin === false &&
      this.props.user.client_admin === false &&
      this.props.user.is_employee === true
    ) {
      return (
        <Redirect
          to={{
            pathname: "/client/dashboard",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (this.props.user.super_admin === true) {
      return (
        <Redirect
          to={{
            pathname: "/super-admin/accounts",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    let percent =
      this.state.overallPercentage > 100 ? 100 : this.state.overallPercentage;
    let clientName = "MakeGood";
    if (
      this.state.OrgclientName != undefined &&
      this.state.OrgclientName != null &&
      this.state.OrgclientName != ""
    ) {
      clientName = this.state.OrgclientName;
    } else if (
      this.state.companyData != undefined &&
      this.state.companyData.clientName != null &&
      this.state.companyData.clientName != ""
    ) {
      clientName = this.state.companyData.clientName;
    }
    return (
      <div className='app-portal'>
        <AppClientHeader
          user={this.props.user}
          appName={clientName}
          logoPath=''
          imageResolution={imageResolution}
          hideNav={true}
          logoUrl={this.state.appThemeData.logo_url}
          isWelcomeScreen={true}
          headerTab={this.props.headerTab}
          disableNav={true}
          isSyncQb={this.state.isSyncQb}
          backTosyncData={this.backTosyncData}
        />
        <div className={`app-portal-canvas consumer-portal canvas-back`}>
          <ClientsNav
            path={this.props.location}
            user={this.props.user}
            disableNav={Math.floor(progressPercent) > 88 ? false : true}
            handleback={this.back}
          />
          {type == "" ? (
            <>{this.welcomeScreen(clientName, percent)}</>
          ) : type == "ADD_ORG" ? (
            <>
              <QuickBookAddOrganizations
                {...this.props}
                appName={this.props.appName}
                user={this.props.user}
                handleback={this.back}
                getOrganizationalDetails={() =>
                  this.getOrganizationalDetails(this)
                }
                organizationDetails={this.state.OrgclientData}
                handleAddOrganizaitions={this.handleAddOrganizaitions}
                window={window.location.origin}
                handleAction={this.handleAction}
                orgKey={this.state.orgKey}
                updateUserLogin={this.props.updateUserLogin}
                isQBDataSyncedFromcheckAuthStatus={
                  this.state.isQBDataSyncedFromcheckAuthStatus
                }
              />
            </>
          ) : type == "CONNECT_ACCOUNT_SYSTEM" ? (
            <>
              <ConnectToAccountView
                user={this.props.user}
                appName={this.props.appName}
                isQuickBookSyncOpen={this.state.isQuickBookSyncOpen}
                updateQBInsideBar={this.updateQBInsideBar}
                isEditPref={true}
                disconnected={false}
                handleback={this.backAddOrg}
                viewpreferences={this.viewpreferences}
                updateModalState={this.props.updateModalState}
                syncQbIcon={this.syncQbIcon}
                handleBackWelcomeScr={this.back}
                getOrganizationalDetails={() => this.getOrganizationalDetails()}
                syncDataToHeaders={this.syncDataToHeaders}
                handleAction={this.handleAction}
                organizationDetails={this.state.OrgclientData}
                orgKey={this.state.orgKey}
              />
            </>
          ) : type == "SYNC_ACCOUNT" ? (
            <>
              <QuickBookDataSyncView
                user={this.props.user}
                appName={this.props.appName}
                isQuickBookSyncOpen={this.state.isQuickBookSyncOpen}
                updateQBInsideBar={this.updateQBInsideBar}
                isEditPref={true}
                disconnected={false}
                handleback={this.backAddOrg}
                viewpreferences={this.viewpreferences}
                updateModalState={this.props.updateModalState}
                syncQbIcon={this.syncQbIcon}
                handleBackWelcomeScr={this.back}
                getOrganizationalDetails={() => this.getOrganizationalDetails()}
                handleAction={this.handleAction}
                backToAccountConnection={() => this.backToAccountConnection()}
                syncDataToHeaders={this.syncDataToHeaders}
                organizationDetails={this.state.OrgclientData}
                orgKey={this.state.orgKey}
              />
            </>
          ) : type == "INVITE_TEAM_MEMBERS" ? (
            <>
              <InviteNewTeamMembers
                props={this.props}
                user={this.props.user}
                appName={this.props.appName}
                loadLogo={() => this.getLogoColorsForPayment()}
                percentage={this.state.overallPercentage}
                OrgclientData={this.state.OrgclientData}
                updateModalState={this.props.updateModalState}
                backTosyncData={this.backTosyncData}
                handleback={() => this.getOrganizationalDetails()}
                backToAccountConnection={() => this.backToAccountConnection()}
                handleAction={this.handleAction}
                organizationDetails={this.state.OrgclientData}
                isQBDataSyncedFromcheckAuthStatus={
                  this.state.isQBDataSyncedFromcheckAuthStatus
                }
                orgKey={this.state.orgKey}
              />
            </>
          ) : type == "SetUp_Payment_Account" ? (
            <SetUpPaymentAccount
              props={this.props}
              user={this.props.user}
              updateModalState={this.props.updateModalState}
              // stripePaymentsDetails={this.state.stripePaymentsDetails}
              handleAction={(type) => this.handleAction(type)}
              handleback={() => this.getOrganizationalDetails()}
              // getStripeConnectDetailsCall={this.getStripeConnectDetailsCall}
              organizationDetails={this.state.OrgclientData}
              orgKey={this.state.orgKey}
            />
          ) : type === "PAYMENT_PREFERENCES" ? (
            <PaymentPreferencesComponent
              props={this.props}
              appName={this.props.appName}
              percentage={this.state.overallPercentage}
              updateModalState={this.props.updateModalState}
              backTosyncData={this.back}
              handleNext={() => this.handleAction("PAYMENT_PORTAL_PREVIEW")}
              handleback={() => this.getOrganizationalDetails()}
              handleAction={(type) => this.handleAction(type)}
              organizationDetails={this.state.OrgclientData}
              orgKey={this.state.orgKey}
            />
          ) : type === "PAYMENT_PORTAL_PREVIEW" ? (
            <PaymentPortalPreview
              updateOnboardingComplete={this.props.updateOnboardingComplete}
              props={this.props}
              history={this.props.history}
              user={this.props.user}
              organizationDetails={this.state.OrgclientData}
              progressPercentage={Math.floor(this.state.overallPercentage)}
              appName={this.props.appName}
              handleGoBackAction={() =>
                this.handleAction("PAYMENT_PREFERENCES")
              }
              updateModalState={this.props.updateModalState}
              backTosyncData={this.back}
              handleNext={() => {}}
              handleback={() => {
                this.getOrganizationalDetails();
              }}
              handleAction={(type) => this.handleAction(type)}
              orgKey={this.state.orgKey}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default WelcomeScreenView;
