import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
// import ReactGA from "react-ga";
import viewIcon from "../../assets/icons/view.png";
//import companyData from "../../assets/styles/theme.json";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsInvoiceSidebar from "../../components/Clients/ClientsInvoiceSidebar";
import InvoiceHistory from "../../components/Clients/Invoice/invoiceHistory";
import InvoiceTasks from "../../components/Clients/Invoice/invoiceTasks";
import deleteIcon from "../../assets/icons/delete.png";
import InvoiceAndLineDetails from "../../components/ObjectList/ClientsAccounts/InvoiceAndLineDetails";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import * as API from "../../utils/api";
import downloadImg from "../../assets/icons/download.png";
import deleteImg from "../../assets/icons/delete.png";
import editImg from "../../assets/icons/edit_blue.svg";
import EditIcon from "../../assets/icons/edit_white.svg";
import HistoryIcon from "../../assets/icons/history.svg";
import { getDollarAmount } from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class ClientsInvoicesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: {},
      taskCategory: "Comms",
      selectedButton: 1,
      isShowHistory: false,
      expandPayment: false,
      expandDoc: false,
      isLoading: true,
      showLoader: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      companyData: {},
    };
  }
  style = {
    eligbleForPayment: {
      padding: "7px",
      borderRadius: "7px",
      backgroundColor: "#0ab894",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: 400,
    },
    inCollection: {
      padding: "7px",
      borderRadius: "7px",
      backgroundColor: "#db2424",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: 400,
    },
  };
  componentDidMount() {
    this.checkAuthStatus();
    this.getOrganizationData();
    this.getInvoiceDetails();
    // ReactGA.pageview("/ClientsInvoicesView");
  }
  checkAuthStatus = () => {
    API.checkAuthStatus(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code === 400) {
        this.props.user.qb_connect = false;
      } else if (
        (typeof data != "undefined" && data.data) ||
        (data != null && data.data)
      ) {
        if (data.data.status && data.data.status === "ACTIVE") {
          this.props.user.qb_connect = true;
        }
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  componentDidUpdate(props) {
    if (this.props.match.params.id != props.match.params.id) {
      this.getInvoiceDetails();
    }
  }

  async getOrganizationData() {
    await API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  getInvoiceDetails = () => {
    let { user } = this.props;
    const invoiceId = this.props.match.params.id;
    API.getInvoiceDetails(user, invoiceId).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((data != undefined || data != null) && data.message === "success") {
        if (data.data) {
          if (
            data.data.user.billing_primary_email === "" ||
            data.data.user.billing_primary_email === null
          ) {
            this.props.updateModalState(true, "ERROR", {
              message:
                "An email does not exist for this account. Invoice communications will not be sent Automatically.",
            });
          }
          this.setState({ invoiceDetails: data.data }, () => {
            this.updateInvoiceData();
            let completedFlag = true;
            if (
              data &&
              data.data &&
              data.data.recent_activities &&
              data.data.recent_activities.tasks.length > 0
            ) {
              data.data.recent_activities.tasks.map((task) => {
                if (task.status == "Completed") {
                  completedFlag = false;
                }
              });
            }
            let noCommunicationFlag = false;
            if (data.is_communication_sent) {
              noCommunicationFlag = false;
            } else if (
              data.qb_email_status == null ||
              data.qb_email_status == ""
            ) {
              noCommunicationFlag = true;
            } else {
              if (
                (data.data.qb_email_status == "NotSet" ||
                  data.data.qb_email_status == "NeedToSend") &&
                completedFlag
              ) {
                noCommunicationFlag = true;
              }
            }
            if (noCommunicationFlag && completedFlag) {
              this.props.updateModalState(true, "SUCCESS", {
                title: "Info",
                message:
                  "No communications have been sent from MakeGood to client for this invoice.",
              });
            }
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updateInvoiceData = () => {
    let { invoiceDetails } = this.state;
    if (
      invoiceDetails &&
      invoiceDetails.invoice_lines &&
      invoiceDetails.invoice_lines.length
    ) {
      invoiceDetails.invoice_lines.map((line, i) => {
        if (line.item_name == "Late fee") {
          invoiceDetails.tax_and_discount_line.push(line);
          // invoiceDetails.invoice_lines.splice(i, 1); //C
        }
      });
    }
    this.setState({ invoiceDetails });
  };

  handleReImportInvoice = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to reimport invoice?",
      confirmClick: this.ReImportInvoice,
      closeOnClickOutside: true,
    });
  };

  ReImportInvoice = (event) => {
    const { invoiceDetails } = this.state;
    const { user } = this.props;
    this.setState({ showLoader: true });
    API.reImportInvoice(user, invoiceDetails.id).then((data) => {
      this.getInvoiceDetails();
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code && data.status_code === 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code === 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.data) {
          this.setState({ invoiceDetails: data.data });
          this.props.updateModalState(true, "SUCCESS", {
            message: "Invoice imported successfully.",
          });
        }
        this.setState({ showLoader: false });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, "debts").then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  deleteAttachment = (doc_id) => {
    let message = "Do you want to delete document?";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: () => {
        API.deleteVerificationDocument(this.props.user, doc_id, "debts").then(
          (data) => {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            if (data && data.data) {
              this.updateDocument(data.data);
            }
          }
        );
      },
      closeOnClickOutside: true,
    });
  };

  handleEditClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        invoiceDetails: this.state.invoiceDetails,
        backPath: `/client/invoices/${this.props.match.params.id}`,
      },
    });
  };

  handleVoidInvoice = (event, message) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: this.voidInvoice,
      closeOnClickOutside: true,
    });
  };

  handleDeleteInvoice = (invoiceID) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete invoice?",
      confirmClick: () => this.deleteInvoice(invoiceID),
      closeOnClickOutside: true,
    });
  };

  deleteInvoice = (invoiceId) => {
    let { history } = this.props;
    API.deleteInvoice(this.props.user, invoiceId).then((data) => {
      if (data) {
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          if (history && history.location) {
            if (
              history.location.state &&
              history.location.state.backPath.includes("accounts")
            ) {
              history.goBack();
            } else {
              history.push("/client/invoices"); // replace with your different path
            }
          }
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  voidInvoice = () => {
    const { invoiceDetails } = this.state;
    this.setState({ isLoading: true });
    API.clientDeleteInvoice(this.props.user, {
      invoice_id: invoiceDetails.id,
    }).then((data) => {
      if (data) {
        this.getInvoiceDetails();
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updateDocument = (data) => {
    let { invoiceDetails } = this.state;
    if (data && data.length) {
      invoiceDetails.document = data;
      this.setState({ invoiceDetails });
    }
  };

  attchDocument = (editData, isEditable) => {
    let props = {
      rowData: this.state.invoiceDetails,
      consumer:
        this.state.invoiceDetails && this.state.invoiceDetails.user
          ? this.state.invoiceDetails.user
          : "",
      editData: editData,
      isEditable: isEditable,
      reloadData: this.reloadData,
      updateDocument: this.updateDocument,
      mgModalTitle: editData ? "Edit Document" : "Upload Document",
    };
    this.props.updateModalState(true, "VALIDATION_DOCUMENT", props);
  };

  showHistory = (isShowHistory) => {
    this.setState({ isShowHistory });
  };

  reloadData = () => {
    this.getInvoiceDetails();
  };

  selectedButton = (item, index) => {
    this.setState({ selectedButton: index + 1 });
  };

  handleHeaderExpander = (event, type) => {
    if (type === "Payment") {
      this.setState({ expandPayment: !this.state.expandPayment });
    } else {
      this.setState({ expandDoc: !this.state.expandDoc });
    }
  };

  viewValidationDoc = (row) => {
    let props = {
      image: row.document,
      type: row.document_name,
      doc_name: row.name,
      doc_id: row.document_id,
      arrange_pdf: true,
      user: this.props.user,
      invoiceId: this.state.invoiceDetails.invoice_number,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amount
        ? invoice.balance
          ? parseFloat(invoice.total_amount) - parseFloat(invoice.balance)
          : invoice.total_amount
        : invoice.total_amount;
    }
    return amount ? amount.toFixed(2) : "0.00";
  };

  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension &&
      (imgExtension.toLowerCase() === "xls" ||
        imgExtension.toLowerCase() === "xlsx" ||
        imgExtension.toLowerCase() === "doc" ||
        imgExtension.toLowerCase() === "docx")
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;
    return (
      <>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 15 }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.document_id, row.name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        <button
          title='Edit'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.attchDocument(row, true)}
        >
          <img src={editImg} width='16' height='16' />
        </button>
        <button
          title='Delete'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.deleteAttachment(row.document_id)}
        >
          <img src={deleteImg} width='28' height='28' />
        </button>
        {flag && (
          <button
            title='View'
            className='view-button'
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='16' height='16' />
          </button>
        )}
      </>
    );
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type === "Payment"
        ? [
            "Date",
            "Amount",
            "Pymt Method",
            "Type",
            "Customer ID / Credit Memo #",
          ]
        : type === "Document"
        ? ["Name", "Description", "Type", "Action"]
        : [];

    columns =
      type === "Document"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.name,
                },
                {
                  key: "Description",
                  title: row.description,
                },
                {
                  key: "Type",
                  title: row.document_type ? row.document_type.name : "",
                },
                {
                  key: "Action",
                  title: this.documentAction(row),
                },
              ],
            };
          })
        : type === "Payment"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Date",
                  title: row.date
                    ? moment(row.date).format("MM/DD/YYYY")
                    : "--/--/----",
                },
                {
                  key: "Amount",
                  title: getDollarAmount(
                    row.is_cancelled ? row.cancel_amount : row.amount
                  ),
                },
                {
                  key: "Pymt Method",
                  title: row.payment_type ? row.payment_type : "n/a",
                },
                {
                  key: "Type",
                  title: row.is_cancelled
                    ? "Cancelled"
                    : row.is_refund
                    ? "Refunded"
                    : row.status === "SUCCESS"
                    ? "Paid"
                    : row.status,
                },
                {
                  key: "Customer ID / Credit Memo #",
                  // title: row.charge_id ? row.charge_id : "n/a",
                  title:
                    row.payment_type === "Credit Memo"
                      ? `#${row.credit_memo_number}`
                      : row.charge_id
                      ? row.charge_id
                      : "n/a",
                },
              ],
            };
          })
        : [];

    return {
      headings,
      columns,
    };
  };

  getClickableHeader = (header, type, data) => {
    let { expandDoc, expandPayment } = this.state;
    let count = data && data.length ? data.length : 0;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>
            {header} ({count})
          </div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          />
        </div>
        {type == "Document" ? (
          <button className='attach-btn' onClick={() => this.attchDocument()}>
            Attach
          </button>
        ) : null}
      </div>
    );
  };

  renderPaymentAndDoc = (data, header, type, emptyMsg) => {
    let newData = [];
    let { expandDoc, expandPayment } = this.state;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    let style = type === "Payment" ? { marginBottom: "2em" } : {};
    if (type === "Payment") {
      newData = data && data.filter((item) => item.amount !== null);
    } else {
      newData = data;
    }
    return (
      <div style={style}>
        {this.getClickableHeader(header, type, newData)}
        {flag && (
          <div>
            {data && data.length ? (
              <div>
                <ClientsCardView data={this.getObjectListData(newData, type)} />
                <HistoryObjectList
                  data={this.getObjectListData(newData, type)}
                  hideForMobile={true}
                  optionalTableClasses={
                    type === "Document" ? "invoice-doc" : ""
                  }
                  optionalClasses='mg-row-pl-15'
                />
              </div>
            ) : (
              this.renderEmpty(emptyMsg)
            )}
          </div>
        )}
      </div>
    );
  };

  headerUtilities = () => {
    const { invoiceDetails } = this.state;
    return (
      <div
        className='app-section-header-utilities'
        style={{ marginBottom: "1.2em" }}
      >
        <div className='admin-search-input-container main-header-search'>
          <div>
            Source:{" "}
            <span style={{ fontWeight: 600 }}>
              {invoiceDetails && invoiceDetails.qb_invoice_id
                ? "QuickBooks"
                : "MakeGood"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  render() {
    const { appName, user, location } = this.props;
    const { isLoading, invoiceDetails, error, isShowHistory, showLoader } =
      this.state;
    let backPath =
      location && location.state && location.state.backPath
        ? location.state.backPath
        : "/client/invoices";

    const props = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>
            {appName ? appName : ""} | Invoice#{" "}
            {invoiceDetails.invoice_number ? invoiceDetails.invoice_number : ""}
          </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsInvoiceSidebar
            {...props}
            user={user}
            backPath={backPath}
            invoiceDetails={invoiceDetails}
            title={this.state.companyData.name}
            isShowHistory={this.state.isShowHistory}
            showHistory={this.showHistory}
          />

          {isShowHistory ? (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <InvoiceHistory
                user={this.props.user}
                invoiceDetails={invoiceDetails}
                goBack={() => this.showHistory(false)}
              />
            </div>
          ) : showLoader ? (
            <div className='app-sidebar-layout-canvas-content'>
              <AppCanvasLoader />
            </div>
          ) : (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <div className='main-header'>
                <div className='he-hed edit-invoice'>
                  Invoice# {invoiceDetails.invoice_number}
                  <div className='edit-circle'>
                    <img
                      src={EditIcon}
                      height={15}
                      width={15}
                      onClick={(event) => this.handleEditClick(event)}
                    />
                  </div>
                  <div className='history-circle'>
                    <img
                      src={HistoryIcon}
                      height={30}
                      width={31}
                      onClick={(event) => this.showHistory(true)}
                    />
                  </div>
                  <div style={{ padding: "3px 0 0 8px", cursor: "pointer" }}>
                    <img
                      title='Delete'
                      src={deleteIcon}
                      height={35}
                      width={35}
                      onClick={() =>
                        this.handleDeleteInvoice(invoiceDetails.id)
                      }
                    />
                  </div>
                  <div className='collection-and-eligiblity-placement'>
                    {invoiceDetails.is_eligible_for_placement &&
                      !invoiceDetails.is_placed_collections && (
                        <button style={this.style.eligbleForPayment}>
                          Eligible for Placement
                        </button>
                      )}
                    {invoiceDetails.is_eligible_for_placement &&
                      invoiceDetails.is_placed_collections && (
                        <button style={this.style.inCollection}>
                          In Collection
                        </button>
                      )}
                  </div>
                </div>
                {this.headerUtilities()}
              </div>
              <InvoiceAndLineDetails
                invoiceDetails={invoiceDetails}
                user={this.props.user}
                fromClient={true}
              />
              {/* Tasks and notes */}
              <InvoiceTasks
                user={this.props.user}
                invoiceDetails={invoiceDetails}
                reloadData={this.reloadData}
                handleReImportInvoice={this.handleReImportInvoice}
                handleVoidInvoice={this.handleVoidInvoice}
                handleDeleteInvoice={this.handleDeleteInvoice}
                updateModalState={this.props.updateModalState}
                getInvoiceDetails={this.getInvoiceDetails}
                // history={this.props.history}
              />
              {this.renderPaymentAndDoc(
                invoiceDetails.document,
                "Documents",
                "Document",
                "No documents available"
              )}
              {this.renderPaymentAndDoc(
                invoiceDetails.payment_history,
                "Payment Details",
                "Payment",
                "No payment details available"
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsInvoicesView;
