import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import {
  getDollarAmount,
  getInvoiceDownload,
  getInvoiceNumber,
} from "../../../utils/helpers";
import downloadImg from "../../././../assets/icons/download.png";
import * as API from "../../../utils/api";
import styles from "./reports.module.scss";
class ClientsCustomerBalanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.overAllTotal = [];
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }
  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(this.props.props.user, doc_id, "debts").then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            let flag = data.data.includes("base64");
            if (!flag) {
              data.data = "data:application/pdf;base64," + data.data;
            }
            let url = data.data;
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  handleClick = (data) => {
    let { history } = this.props.props;
    history.push({
      pathname: `/client/invoices/${data.id}`,
      state: {
        invoiceDetails: data,
        backPath: `/client/reports/5`,
      },
    });
  };

  getReportData = (data) => {
    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Due Date",
        "Amount",
        "Open Balance",
        "Balance",
        "Action",
      ],

      columns: data,
    };
  };

  renderOverAllTotal = () => {
    return (
      <tbody>
        <tr className='row-total over-all-total'>
          {this.overAllTotal &&
            this.overAllTotal.map((col, i) => {
              return (
                <td
                  key={shortid.generate()}
                  className={` ${styles.rowData} bal-sub-total`}
                >
                  {i === 0
                    ? col
                    : col
                    ? getDollarAmount(Number(col))
                    : i === 3
                    ? ""
                    : "$0.00"}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  getTotal = (title, e, total) => {
    total[0] = "Total for " + title;

    total[4] = total[4] != null ? Number(total[4]) : 0;
    total[4] += Number(e.total_amt) + Number(e.tax_amount);

    total[5] = total[5]
      ? Number(total[5]) + Number(e.balance)
      : Number(e.balance);
    total[3] = "";
    total[1] = "";
    this.overAllTotal[0] = "Total";
    this.overAllTotal[4] = this.overAllTotal[4]
      ? Number(this.overAllTotal[4]) + Number(e.total_amt)
      : Number(e.total_amt);
    this.overAllTotal[5] = this.overAllTotal[5]
      ? Number(this.overAllTotal[5]) + Number(e.balance)
      : Number(e.balance);
    this.overAllTotal[3] = "";
    return total;
  };

  getTableRows = (item, calBal) => {
    let downloadInvoice = this.downloadInvoice;
    let handleClick = this.handleClick;
    this.balance = calBal
      ? Number(item.balance) + Number(calBal)
      : Number(item.balance).toFixed(2);
    return [
      {
        title: this.getDate(item.txn_date),
      },
      {
        title: "Invoice",
      },
      {
        title: getInvoiceNumber(item, handleClick),
      },
      {
        title: this.getDate(item.due_date),
      },
      {
        title: getDollarAmount(
          Number(item.total_amt) + (parseFloat(item.tax_amount) || 0)
        ),
      },
      {
        title: getDollarAmount(Number(item.balance)),
      },
      {
        title: getDollarAmount(Number(this.balance)),
      },
      {
        title: getInvoiceDownload(item, downloadInvoice),
      },
    ];
  };

  renderTableBody = (data) => {
    return (
      data &&
      data.map((item) => {
        let total = [];
        let mapData = item.list || item.data;
        this.balance = "";
        return (
          <tbody key={shortid.generate()}>
            {mapData && mapData.length ? (
              <tr>
                <td className={` ${styles.rowData} inner-title`}>
                  {item.user_name ? item.user_name : ""}
                </td>
              </tr>
            ) : (
              <tr className='empty-row' />
            )}
            {mapData && mapData.length ? (
              mapData.map((d, i) => {
                let rows = this.getTableRows(d, this.balance);
                total = this.getTotal(item.user_name, d, total);
                return (
                  <tr
                    key={shortid.generate()}
                    className={`${styles.rowData} row-total`}
                  >
                    {rows &&
                      rows.map((col) => {
                        return (
                          <td
                            key={shortid.generate()}
                            className={styles.rowData}
                          >
                            {col.title}
                          </td>
                        );
                      })}
                  </tr>
                );
              })
            ) : (
              <tr className='empty-row' />
            )}
            {total && total.length > 0 && (
              <tr className={`${styles.rowData} row-total`}>
                {total.map((col, i) => {
                  return (
                    <td
                      key={shortid.generate()}
                      className={`${styles.rowData} bal-sub-total`}
                      style={{
                        textAlign: i === 0 ? "start" : "center",
                        margin:
                          i === 4
                            ? "0 0 0 0"
                            : i === 5
                            ? "0 0 0 -1.5rem"
                            : i === 3
                            ? "0 0 0 2rem"
                            : "",
                      }}
                    >
                      {i === 0 ? col : col && getDollarAmount(Number(col))}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        );
      })
    );
  };

  render() {
    let { data } = this.state;
    return (
      <Table hover striped className='aging-detail-report collection-report'>
        <thead className={styles.headerFix}>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={styles.headingStyle}
                    style={{ borderTop: "none" }}
                  >
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        {data && this.renderTableBody(data.columns)}
        {this.renderOverAllTotal()}
      </Table>
    );
  }
}

export default ClientsCustomerBalanceDetail;
