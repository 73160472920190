import React, { Component } from "react";
import AppClientHeader from "../../../components/App/AppClientHeader";
import ClientsNav from "../../../components/Clients/ClientsNav";
import * as API from "../../../utils/api";
import CustomizeWorkFlowStep1 from "./CustomizeWorkFlowStep1";
import CustomizeWorkFlowStep2 from "./CustomizeWorkFlowStep2";
import CustomizeWorkFlowStep3 from "./CustomizeWorkFlowStep3";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import CustomizeWorkFlowStep4 from "./CustomizeWorkFlowStep4";
import CustomizeWorkFlowStep5 from "./CustomizeWorkFlowStep5";
import CustomizeWorkFlowStep6 from "./CustomizeWorkFlowStep6";
import CustomizeWorkFlowStep7 from "./CustomizeWorkFlowStep7";
import CustomizeWorkFlowStep8 from "./CustomizeWorkFlowStep8";
import CustomizeWorkFlowStep9 from "./CustomizeWorkFlowStep9";
import { getBaseUrl } from "../../../utils/api";
import Emitter from "../../../utils/event-emitter";
import CustomizeWorkFlowStep10 from "./CustomizeWorkFlowStep10";
import { Redirect } from "react-router";
import Cookies from "universal-cookie";
import OnboardingContext from "../../../Context/OnboardingContext";
class CustomizeWorkFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: "",
      logoUrl: "",
      organizationDetails: "",
      isLoading: true,
    };
  }
  static contextType = OnboardingContext;

  state = {
    currentStep: "",
    progressPercentage: 0,
    appThemeData: {},
    organizationDetails: "",
    isLoading: true,
    isSyncQb: false,
    Interval: "",
  };

  componentWillMount() {
    if (localStorage.getItem("isSyncQb") === "true") {
      this.setState({ isSyncQb: true });
      let Interval = setInterval(this.checkAuthStatus, 5000);

      this.setState({ Interval });
    }
  }

  checkAuthStatus = (cancel_sync = false) => {
    API.checkAuthStatus(this.props.user, cancel_sync)
      .then((data) => {
        if (data && data.status_code && data.status_code === 400) {
          this.setState({ isLoading: false });
          //call to update notification bell count
          Emitter.emit("IS_QUICKBOOK_CONNECTED", false);
        } else if (
          (typeof data != "undefined" && data.data) ||
          (data != null && data.data)
        ) {
          if (data.data.sync_status.sync_status == "COMPLETED") {
            this.setState({ isSyncQb: false });
            localStorage.removeItem("isSyncQb");
            clearInterval(this.state.Interval);
          }
        } else {
          this.dismissCall();
          this.setState({
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        console.log("---------------------------------err", e);
      });
  };

  getLogoColors = (url) => {
    this.setState({
      logoUrl: url,
    });
  };

  componentDidMount() {
    localStorage.setItem("type", "INVITE_TEAM_MEMBERS");
    const { progressPercent } = this.context;
    this.getOrganizationalDetails();
    API.getLogoColors().then((res) => {
      this.setState({
        ...this.state,
        logoUrl: res.data.logo_url,
        appThemeData: res.data,
      });
    });
  }

  componentWillUnmount() {
    localStorage.setItem("type", "");
    localStorage.removeItem("step1");
    localStorage.removeItem("hereClick");
    Emitter.off("IS_QUICKBOOK_SYNC");
  }
  handleNext = (res) => {
    let { history } = this.props;
    if (res === "step2") {
      this.setState({
        currentStep: 20,
      });
      history.push("/welcome-screen/add-banking-info");
    } else if (res === "step3") {
      localStorage.removeItem("hereClick");
      this.setState({
        currentStep: 30,
      });
      history.push("/welcome-screen/select-payment-portal");
    } else if (res === "step4") {
      this.setState({
        currentStep: 40,
      });
      history.push("/welcome-screen/upload-W9-form");
    } else if (res === "step5") {
      this.setState({
        currentStep: 50,
      });
      history.push("/welcome-screen/default-credit-terms");
    } else if (res === "step6") {
      this.setState({
        currentStep: 60,
      });
      history.push("/welcome-screen/account-placement-eligibility");
    } else if (res === "step7") {
      localStorage.removeItem("editLogo");
      this.setState({
        currentStep: 70,
      });
      history.push("/welcome-screen/invoice-branding-and-comms");
    } else if (res === "step8") {
      this.setState({
        currentStep: 80,
      });
      history.push("/welcome-screen/communication-preferences");
    } else if (res === "step9") {
      this.setState({
        currentStep: 90,
      });
      history.push("/welcome-screen/schedule-communications");
    } else if (res === "step10") {
      this.setState({
        currentStep: 100,
      });
      history.push("/welcome-screen/schedule-go-live-date");
    }
  };
  handleBack = (res) => {
    let { history } = this.props;
    if (res === "step1") {
      this.setState({
        currentStep: 10,
      });
      history.push("/welcome-screen/add-billing-details");
    } else if (res === "step2") {
      this.setState({
        currentStep: 20,
      });
      history.push("/welcome-screen/add-banking-info");
    } else if (res === "step3") {
      localStorage.removeItem("hereClick");
      this.setState({
        currentStep: 30,
      });
      history.push("/welcome-screen/select-payment-portal");
    } else if (res === "step4") {
      this.setState({
        currentStep: 40,
      });
      history.push("/welcome-screen/upload-W9-form");
    } else if (res === "step5") {
      this.setState({
        currentStep: 50,
      });
      history.push("/welcome-screen/default-credit-terms");
    } else if (res === "step5") {
      this.setState({
        currentStep: 50,
      });
      history.push("/welcome-screen/default-credit-terms");
    } else if (res === "step6") {
      this.setState({
        currentStep: 60,
      });
      history.push("/welcome-screen/account-placement-eligibility");
    } else if (res === "step7") {
      localStorage.removeItem("editLogo");
      this.setState({
        currentStep: 70,
      });
      history.push("/welcome-screen/invoice-branding-and-comms");
    } else if (res === "step8") {
      this.setState({
        currentStep: 80,
      });
      history.push("/welcome-screen/communication-preferences");
    } else if (res === "step9") {
      this.setState({
        currentStep: 90,
      });
      history.push("/welcome-screen/schedule-communications");
    } else if (res === "step10") {
      this.setState({
        currentStep: 100,
      });
      history.push("/welcome-screen/schedule-go-live-date");
    }
  };

  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    this.setState({ isLoading: true });
    if (authuser && authuser != null) {
      API.getOrgDetails(user).then((data) => {
        if ((typeof data != "undefined" || data != null) && data.data) {
          this.setState(
            {
              organizationDetails: data.data,
              progressPercentage: data.data.organization.onboarding_percentage,
            },
            () => this.getMergeVariablesList()
          );
          let { input, organizationDetails } = this.state;
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              isLoading: false,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      // this.props.handleSignOut();
    }
  };

  reloadData = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    if (authuser && authuser != null) {
      API.getOrgDetails(user).then((data) => {
        if ((typeof data != "undefined" || data != null) && data.data) {
          this.setState(
            {
              organizationDetails: data.data,
              progressPercentage: data.data.organization.onboarding_percentage,
            },
            () => this.reloadMergeData()
          );
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      // this.props.handleSignOut();
    }
  };
  getMergeVariablesList = () => {
    let currentLabel = this.props.type;
    let temp = 0;
    let { history, location } = this.props;
    const accountId = this.state.organizationDetails.organization.id;
    API.getMergeVariablesListClient(this.props.user, accountId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        temp = data.workflow_percentage;
        this.setState(
          (state) => ({
            ...state,
            mergeVarData: data.data,
          }),
          () => {
            if (currentLabel === "CREDIT_TERMS") {
              if (localStorage.getItem("hereClick") === "true") {
                this.setState({ currentStep: 20, isLoading: false });
                history.push("/welcome-screen/add-banking-info");
              } else {
                this.setState({ currentStep: 10, isLoading: false });
                history.push("/welcome-screen/add-billing-details");
              }
            } else if (currentLabel === "INVOICE_PREFERENCES") {
              this.setState({ currentStep: 70, isLoading: false });
            } else if (currentLabel === "SCHEDULE_COMMUNICATIONS") {
              this.setState({ currentStep: 90, isLoading: false });
            } else if (currentLabel === "GO_LIVE_DATE") {
              this.setState({ currentStep: 100, isLoading: false });
            } else if (localStorage.getItem("hereClick") === "true") {
              this.setState({ currentStep: 20, isLoading: false });
              history.push("/welcome-screen/add-banking-info");
            }
            // else if (localStorage.getItem("editLogo") === "true"){
            //   this.setState({ currentStep: 62.5, isLoading: false });

            // }
            else {
              this.setState({ currentStep: 10, isLoading: false });
            }
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            isLoading: false,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  reloadMergeData = () => {
    let temp = 0;
    const accountId = this.state.organizationDetails.organization.id;
    API.getMergeVariablesListClient(this.props.user, accountId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        temp = data.workflow_percentage;
        this.setState((state) => ({
          ...state,
          mergeVarData: data.data,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  renderStep = () => {
    let { isLoading } = this.state;
    return isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : this.state.currentStep <= 10 ? (
      <CustomizeWorkFlowStep1
        {...this.props}
        user={this.props.user}
        getOrganizationalDetails={() => this.reloadData()}
        handleAddOrganizaitions={this.handleAddOrganizaitions}
        window={window.location.origin}
        orgData={this.state.organizationDetails}
        mergeVarData={this.state.mergeVarData}
        handleNext={this.handleNext}
      />
    ) : (this.state.currentStep > 10 && this.state.currentStep <= 20) ||
      localStorage.getItem("hereClick") === "true" ? (
      <>
        <CustomizeWorkFlowStep2
          {...this.props}
          user={this.props.user}
          handleback={this.handleBack}
          getOrganizationalDetails={() => this.reloadData()}
          window={window.location.origin}
          orgData={this.state.organizationDetails}
          mergeVarData={this.state.mergeVarData}
          handleNext={this.handleNext}
        />
      </>
    ) : this.state.currentStep > 20 && this.state.currentStep <= 30 ? (
      <>
        <CustomizeWorkFlowStep3
          {...this.props}
          user={this.props.user}
          handleback={this.handleBack}
          getOrganizationalDetails={() => this.reloadData()}
          handleAddOrganizaitions={this.handleAddOrganizaitions}
          orgData={this.state.organizationDetails}
          mergeVarData={this.state.mergeVarData}
          window={window.location.origin}
          handleNext={this.handleNext}
        />
      </>
    ) : this.state.currentStep > 30 && this.state.currentStep <= 40 ? (
      <>
        <CustomizeWorkFlowStep4
          {...this.props}
          orgUrl={window.location.origin}
          user={this.props.user}
          orgData={this.state.organizationDetails}
          updateModalState={this.props.updateModalState}
          handleBack={this.handleBack}
          handleNext={this.handleNext}
        />
      </>
    ) : this.state.currentStep > 40 && this.state.currentStep <= 50 ? (
      <>
        <CustomizeWorkFlowStep5
          {...this.props}
          handlebacktoStep4={this.handleBack}
          user={this.props.user}
          orgData={this.state.organizationDetails}
          updateModalState={this.props.updateModalState}
          handleNext={this.handleNext}
        />
      </>
    ) : this.state.currentStep > 50 && this.state.currentStep <= 60 ? (
      <CustomizeWorkFlowStep6
        {...this.props}
        orgUrl={window.location.origin}
        user={this.props.user}
        updateModalState={this.props.updateModalState}
        orgData={this.state.organizationDetails}
        handleBack={this.handleBack}
        handleNext={this.handleNext}
      />
    ) : this.state.currentStep > 60 && this.state.currentStep <= 70 ? (
      <CustomizeWorkFlowStep7
        {...this.props}
        user={this.props.user}
        mergeVarData={this.state.mergeVarData}
        organizationDetails={this.state.organizationDetails}
        updateModalState={this.props.updateModalState}
        handleNext={this.handleNext}
        updateLogo={this.getLogoColors}
        handleBack={this.handleBack}
        getMergeVariablesList={this.getMergeVariablesList}
      />
    ) : this.state.currentStep > 70 && this.state.currentStep <= 80 ? (
      <CustomizeWorkFlowStep8
        {...this.props}
        handleBack={this.handleBack}
        updateModalState={this.props.updateModalState}
        user={this.props.user}
        organizationDetails={this.state.organizationDetails}
        handleNext={this.handleNext}
      />
    ) : this.state.currentStep > 80 && this.state.currentStep <= 90 ? (
      <CustomizeWorkFlowStep9
        {...this.props}
        handleBack={this.handleBack}
        updateModalState={this.props.updateModalState}
        user={this.props.user}
        handleNext={this.handleNext}
      />
    ) : this.state.currentStep > 90 && this.state.currentStep <= 100 ? (
      <CustomizeWorkFlowStep10
        {...this.props}
        updateModalState={this.props.updateModalState}
        user={this.props.user}
        handleBack={this.handleBack}
        handleNext={this.handleNext}
      />
    ) : (
      <CustomizeWorkFlowStep1
        {...this.props}
        user={this.props.user}
        getOrganizationalDetails={() => this.getOrganizationalDetails()}
        window={window.location.origin}
        handleNext={this.handleNext}
      />
    );
  };
  render() {
    const { progressPercent } = this.context;

    if (
      this.props.user.admin === false &&
      this.props.user.client_admin === false &&
      (this.props.user.is_employee === false ||
        this.props.user.is_employee === null)
    ) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard/accounts/invoices",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (
      this.props.user.admin === false &&
      this.props.user.client_admin === false &&
      this.props.user.is_employee === true
    ) {
      return (
        <Redirect
          to={{
            pathname: "/client/dashboard",
            state: { from: this.props.location },
          }}
        />
      );
    }
    return (
      <>
        <div className='app-portal'>
          <AppClientHeader
            user={this.props.user}
            appName={this.props.clientName}
            logoPath='/client/accounts/'
            hideNav={true}
            isWelcomeScreen={true}
            logoUrl={this.state.logoUrl}
            headerTab={this.props.headerTab}
            disableNav={true}
            isSyncQb={this.state.isSyncQb}
            backTosyncData={this.backTosyncData}
          />
          <div className={`app-portal-canvas consumer-portal canvas-back`}>
            <ClientsNav
              path={this.props.location}
              disableNav={Math.floor(progressPercent) > 88 ? false : true}
              user={this.props.user}
              handleback={this.back}
            />
            {this.renderStep()}
          </div>
        </div>
      </>
    );
  }
}

export default CustomizeWorkFlow;
